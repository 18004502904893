import React from "react";
import NextSale from "./NextSale";

export default () => {
    return (
        <NextSale
            config={{
                alias: "nextSale",
                auctionHouseDetails: "The Auction House, Nottingham",
                date: "23rd December 2019",
                horizontalTextPosition: "Left",
                id: 1180,
                image: "/media/1011/next-sale-slider.png",
                name: "Next sale",
                time: "11.00am",
                title: "Antique & Collectors Sale",
                updatedAt: "2019-07-18T15:02:24",
                imageRoot: "https://mkslides.auction2.wrvc.co.uk",
                nextSaleBidOnlineText: "Bid online - www.mellorsandkirk.com",
                nextSaleIntroText: "NEXT SALE"
            }}
        />
    );
};
