import { useState, useEffect } from "react";

interface DataResponse {
    isLoading: boolean;
    data: any;
}

const useFetch = (url: string, defaultResponse: DataResponse) => {
    const [data, setData] = useState(defaultResponse);

    const getDataFromAPI = async () => {
        try {
            console.log("url", url);
            const res = await fetch(url);
            console.log("res", res);
            const data = await res.json();
            setData({
                isLoading: false,
                data
            });
        } catch (e) {
            console.error(e);
            setData({
                isLoading: false,
                data: e
            });
        }
    };

    useEffect(() => {
        getDataFromAPI();
    }, []);

    return data;
};

export default useFetch;
