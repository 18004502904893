import React from "react";
import ToBeSoldLot from "./ToBeSoldLot";

export default () => {
    return (
        <ToBeSoldLot
            config={{
                // alias: "toBeSoldLot",
                // estimate: "£5,000 - £7,000",
                // horizontalTextPosition: "Left",
                // id: 1177,
                // image: "/media/1002/_september-archer.png",
                // imageRoot: "https://mkslides.auction2.wrvc.co.uk",
                // lotBuyersPremiumText: "+ Buyer’s Premium",
                // name: "Future lot 1",
                // saleDetailsLine1: "22nd September,",
                // saleDetailsLine2: "Fine Art Sale",
                // unsoldLotConsignInfo:
                //     "To consign for this sale please visit www.mellorsandkirk.com",
                // unsoldLotEstimateText: "Estimate",
                // unsoldLotIntroText: "To be sold",
                // updatedAt: "2019-07-19T13:35:46"

                alias: "toBeSoldLot",
                estimate: "£500 - £700",
                horizontalTextPosition: "Right",
                id: 1179,
                image: "/media/1012/september-caddy.png",
                imageRoot: "https://mkslides.auction2.wrvc.co.uk",
                lotBuyersPremiumText: "+ Buyer’s Premium",
                name: "Future lot 3",
                saleDetailsLine1: "22nd September,",
                saleDetailsLine2: "Fine Art Sale",
                unsoldLotConsignInfo:
                    "To consign for this sale please visit www.mellorsandkirk.com",
                unsoldLotEstimateText: "Estimate",
                unsoldLotIntroText: "To be sold",
                updatedAt: "2019-07-19T13:35:46"
            }}
        />
    );
};
