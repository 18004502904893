import React, { useState, useMemo, useContext, createContext } from "react";

const AppContext = createContext(null);

function AppContextProvider(props) {
    const [endpoint, setEndpoint] = useState<string>(
        "https://mkslides.auction2.wrvc.co.uk/api/slides"
    );

    const values = useMemo(
        () => {
            return {
                endpoint,
                setEndpoint
            };
        },
        [endpoint]
    );

    return <AppContext.Provider value={values} {...props} />;
}

const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within a AppContextProvider");
    }
    const { endpoint } = context;
    return { endpoint };
};

export { AppContextProvider, useAppContext };
