import React from "react";
import WebImage from "./WebImage";
import BgWebImage from "./BgWebImage";

interface IPropsToBeSoldLot {
    config: IToBeSoldLotConfig;
}

interface IToBeSoldLotConfig {
    alias: string;
    estimate: string;
    horizontalTextPosition: string;
    id: number;
    image: string;
    imageRoot: string;
    lotBuyersPremiumText: string;
    name: string;
    saleDetailsLine1: string;
    saleDetailsLine2: string;
    unsoldLotConsignInfo: string;
    unsoldLotEstimateText: string;
    unsoldLotIntroText: string;
    updatedAt: string;
}

export default ({ config }: IPropsToBeSoldLot) => {
    /*
    alias: "toBeSoldLot",
    estimate: "£5,000 - £7,000",
    horizontalTextPosition: "Left",
    id: 1177,
    image: "/media/1002/_september-archer.png",
    imageRoot: "https://mkslides.auction2.wrvc.co.uk",
    lotBuyersPremiumText: "+ Buyer’s Premium",
    name: "Future lot 1",
    saleDetailsLine1: "22nd September,",
    saleDetailsLine2: "Fine Art Sale",
    unsoldLotConsignInfo:
        "To consign for this sale please visit www.mellorsandkirk.com",
    unsoldLotEstimateText: "Estimate",
    unsoldLotIntroText: "To be sold",
    updatedAt: "2019-07-19T13:35:46"
*/

    const textCssClass = config.horizontalTextPosition === "Left" ? "left" : "right";
    const imageCssClass = config.horizontalTextPosition === "Left" ? "right" : "left";

    console.log("tobesoldlot", config);
    return (
        <div className={`slide ${config.alias}`}>
            <div className={`half ${textCssClass}`}>
                <div className="info-box-container" style={{ height: "100%" }}>
                    <div className={`info-box cover`}>
                        <h2>{config.unsoldLotIntroText}</h2>
                        <h1 className="nobr">
                            {config.saleDetailsLine1}
                            <br />
                            {config.saleDetailsLine2}
                        </h1>
                        <h2>{config.unsoldLotEstimateText}</h2>
                        <h1 className="nobr">{config.estimate}</h1>
                        <h3 className="italic">{config.lotBuyersPremiumText}</h3>
                        <h3 className="lg">{config.unsoldLotConsignInfo}</h3>
                    </div>
                </div>
            </div>
            <div className={`half ${imageCssClass}`}>
                <div className="image-container">
                    <BgWebImage imageRoot={config.imageRoot} image={config.image} />
                </div>
            </div>
        </div>
    );
};
