import React from "react";
import SoldLot from "./SoldLot";

export default () => {
    return (
        <SoldLot
            config={{
                id: 1234,
                alias: "soldLot",
                horizontalTextPosition: "Right",
                verticalTextAlignment: "Bottom",
                image: "/media/1012/september-caddy.png",
                name: "Sold lot 4",
                soldFor: "£38,000",
                soldLotPriceRealisedText: "Price realised at auction by Mellors & Kirk",
                lotBuyersPremiumText: "+ Buyer's Premium",
                imageRoot: "https://mkslides.auction2.wrvc.co.uk",
                updatedAt: "2019-07-19T11:53:43"
            }}
        />
    );
};
