import React from "react";
import WebImage from "./WebImage";
import BgWebImage from "./BgWebImage";

interface IPropsSoldLot {
    config: ISoldLotConfig;
}

interface ISoldLotConfig {
    id: number;
    alias: string;
    name: string;
    horizontalTextPosition: string;
    verticalTextAlignment: string;
    soldFor: string;
    soldLotPriceRealisedText: string;
    lotBuyersPremiumText: string;
    imageRoot: string;
    image: string;
    updatedAt: string;
}

export default ({ config }: IPropsSoldLot) => {
    /*
    alias: "soldLot"
horizontalTextPosition: "Right"
id: 1176
image: "/media/1008/lot516.png"
name: "Sold lot 4"
soldFor: "£38,000"
updatedAt: "2019-07-19T11:53:43"
verticalTextAlignment: "Bottom"

soldLotPriceRealisedText
lotBuyersPremiumText
*/

    console.log(config);
    const textCssClassVertical = config.verticalTextAlignment === "Bottom" ? "bottom" : "top";

    const textCssClass = config.horizontalTextPosition === "Left" ? "left" : "right";
    const imageCssClass = config.horizontalTextPosition === "Left" ? "right" : "left";

    return (
        <div className={`slide ${config.alias}`}>
            <div className={`half ${textCssClass}`}>
                <div className="info-box-container">
                    <div className={`info-box ${textCssClassVertical}`}>
                        <h1 className="lg center">{config.soldFor}</h1>
                        <h2 className="center">{config.soldLotPriceRealisedText}</h2>
                        <h3 className="center italic">{config.lotBuyersPremiumText}</h3>
                    </div>
                </div>
            </div>
            <div className={`half ${imageCssClass}`}>
                <div className="image-container">
                    <BgWebImage imageRoot={config.imageRoot} image={config.image} />
                </div>
            </div>
        </div>
    );
};
