import React from "react";
import WebImage from "./WebImage";
import BgWebImage from "./BgWebImage";

interface IPropsNextSale {
    config: INextSaleConfig;
}

interface INextSaleConfig {
    alias: string;
    auctionHouseDetails: string;
    date: string;
    horizontalTextPosition: string;
    id: number;
    image: string;
    name: string;
    time: string;
    title: string;
    updatedAt: string;
    imageRoot: string;
    nextSaleIntroText: string;
    nextSaleBidOnlineText: string;
}

export default ({ config }: IPropsNextSale) => {
    console.log("next sale", config);

    const textCssClass = config.horizontalTextPosition === "Left" ? "left" : "right";
    const imageCssClass = config.horizontalTextPosition === "Left" ? "right" : "left";

    return (
        <div className={`slide ${config.alias}`}>
            <div className={`half ${textCssClass}`}>
                <div className="info-box-container">
                    <div className={`info-box cover`}>
                        <h2>{config.nextSaleIntroText}</h2>
                        <h1>{config.title}</h1>
                        <h2>
                            {config.date}
                            <br />
                            {config.time}
                            <br />
                            {config.auctionHouseDetails}
                        </h2>
                        <h3 className="margin-top">{config.nextSaleBidOnlineText}</h3>
                    </div>
                </div>
            </div>
            <div className={`half ${imageCssClass}`}>
                <div className="image-container">
                    <BgWebImage imageRoot={config.imageRoot} image={config.image} />
                </div>
            </div>
        </div>
    );
};
