import React from "react";

export default ({ imageRoot, image }) => {
    const path = `${imageRoot}${image}`;
    //console.log("path", path);
    return (
        <div className="bg-img-container">
            <div className="bg-img fw" style={{ backgroundImage: `url(${path})` }} />
        </div>
    );
};
