import React from "react";
import OpeningHours from "./OpeningHours";

export default () => {
    return (
        <OpeningHours
            config={{
                alias: "openingHours",
                content: "<p>Friday 10am-1pm <br />Saturday 10am-1pm</p>",
                id: 1183,
                imageRoot: "https://mkslides.auction2.wrvc.co.uk",
                name: "Opening hours",
                updatedAt: "2019-07-18T15:12:00"
            }}
        />
    );
};
