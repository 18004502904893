import React, { useState } from "react";
import Slider from "react-slick";
import moment from "moment";
import SoldLot from "./SoldLot";
import { useAppContext } from "../contexts/AppContext";
import useFetch from "../hooks/useFetch";
import NextSale from "./NextSale";
import OpeningHours from "./OpeningHours";
import Promotion from "./Promotion";
import ToBeSoldLot from "./ToBeSoldLot";

// https://react-slick.neostack.com/docs/api
export default () => {
    const { endpoint } = useAppContext();
    const [slider, setSlider] = useState(null);

    const userFetchResponse = useFetch(endpoint, { isLoading: true, data: null });

    if (!userFetchResponse.data || userFetchResponse.isLoading) {
        return null;
    }

    const {
        transitionDuration,
        showEachSlideFor,
        imageRoot,
        unsoldLotIntroText,
        soldLotPriceRealisedText,
        lotBuyersPremiumText,
        unsoldLotEstimateText,
        unsoldLotConsignInfo,
        nextSaleIntroText,
        nextSaleBidOnlineText,
        children
    } = userFetchResponse.data;

    /*
    id: 1157
    imageRoot: "https://mkslides.auction2.wrvc.co.uk"
    lotBuyersPremiumText: "+ Buyer’s Premium"
    name: "Slides"
    nextSaleBidOnlineText: "Bid online - www.mellorsandkirk.com"
    nextSaleIntroText: "NEXT SALE"
    showEachSlideFor: 3000
    soldLotPriceRealisedText: "Price realised at auction by Mellors & Kirk"
    transitionDuration: 600
    unsoldLotConsignInfo: "To consign for this sale please visit www.mellorsandkirk.com"
    unsoldLotEstimateText: "Estimate"
    unsoldLotIntroText: "To be sold"
    updatedAt: "2019-07-19T13:18:00"
    */

    //console.log("userFetchResponse.data", userFetchResponse.data);

    const slides = children
        ? children.map(child => {
              //console.log("child", child);
              switch (child.alias) {
                  case "nextSale":
                      return (
                          <NextSale
                              key={child.id}
                              config={{
                                  ...child,
                                  imageRoot,
                                  nextSaleIntroText,
                                  nextSaleBidOnlineText
                              }}
                          />
                      );
                  case "openingHours":
                      return <OpeningHours key={child.id} config={{ ...child, imageRoot }} />;
                  case "promotion":
                      return <Promotion key={child.id} config={{ ...child, imageRoot }} />;
                  case "soldLot":
                      return (
                          <SoldLot
                              key={child.id}
                              config={{
                                  ...child,
                                  soldLotPriceRealisedText,
                                  lotBuyersPremiumText,
                                  imageRoot
                              }}
                          />
                      );
                  case "toBeSoldLot":
                      return (
                          <ToBeSoldLot
                              key={child.id}
                              config={{
                                  ...child,
                                  unsoldLotIntroText,
                                  unsoldLotEstimateText,
                                  unsoldLotConsignInfo,
                                  lotBuyersPremiumText,
                                  imageRoot
                              }}
                          />
                      );
                  default:
                      return <div>No slide definition</div>;
              }
          })
        : [];

    const count = slides.length;

    const settings = {
        dots: false,
        infinite: false,
        speed: transitionDuration,
        autoplaySpeed: showEachSlideFor,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        autoplay: true,
        afterChange: index => {
            if (index === count - 1) {
                console.log("just loaded last slide");
                if (slider) {
                    console.log("paused slider");
                    slider.slickPause();
                }

                setTimeout(() => window.location.reload(), showEachSlideFor - transitionDuration);
            }
        }
    };

    return (
        <Slider ref={c => setSlider(c)} {...settings}>
            {slides}
        </Slider>
    );
};
