import React from "react";
import { render } from "react-dom";
import { AppContextProvider } from './contexts/AppContext';
import App from "./components/App";

render(
    <AppContextProvider>
        <App />
    </AppContextProvider>
    ,
    document.getElementById('react-app-tv-slides') as HTMLElement
);