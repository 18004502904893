import React from "react";
import Html from "./Util/Html";
import WebImage from "./WebImage";
import BgWebImage from "./BgWebImage";

interface IPropsPromotion {
    config: IPromotionConfig;
}

interface IPromotionConfig {
    alias: string;
    heading: string;
    preHeadingContent: string;
    content: string;
    horizontalTextPosition: string;
    id: number;
    image: string;
    imageRoot: string;
    name: string;
    updatedAt: string;
}

export default ({ config }: IPropsPromotion) => {
    /*
    alias: "promotion"
content: "<p>www.mellorsandkirk.com<br />Tel: 0115 979 0000</p>"
heading: "Free Home Visit"
horizontalTextPosition: "Right"
id: 1186
image: "/media/1006/free-home-visit.png"
name: "Free home visit promo"
preHeadingContent: "<p>Please contact us if you would like a</p>"
*/
    console.log("promotion", config);

    const textCssClass = config.horizontalTextPosition === "Left" ? "left" : "right";
    const imageCssClass = config.horizontalTextPosition === "Left" ? "right" : "left";

    return (
        <div className={`slide ${config.alias}`}>
            <div className={`half ${textCssClass}`}>
                <div className="info-box-container">
                    <div className={`info-box cover`}>
                        {config.preHeadingContent ? (
                            <div className="h2">
                                <Html content={config.preHeadingContent} />
                            </div>
                        ) : null}
                        <h1>{config.heading}</h1>

                        {config.content ? (
                            <div className="h2">
                                <Html content={config.content} />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className={`half ${imageCssClass}`}>
                <div className="image-container">
                    <BgWebImage imageRoot={config.imageRoot} image={config.image} />
                </div>
            </div>
        </div>
    );
};
