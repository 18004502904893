import React from "react";
import Html from "./Util/Html";

interface IPropsOpeningHours {
    config: IOpeningHoursConfig;
}

interface IOpeningHoursConfig {
    alias: string;
    content: string;
    id: number;
    imageRoot: string;
    name: string;
    updatedAt: string;
}

export default ({ config }: IPropsOpeningHours) => {
    console.log("openinghours", config);

    return (
        <div className={`slide ${config.alias}`}>
            <div className={`full`}>
                <div className="info-box-container">
                    <div className={`info-box cover center`}>
                        <h1>This office is</h1>
                        <h1 className="xl">OPEN</h1>
                        <div className="h1">
                            <Html content={config.content} />
                        </div>
                        <h2>(Out of hours by appointment)</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};
