import { BrowserRouter as Router, Route, Switch, Link  } from "react-router-dom";
import React from "react";
import Slides from "./Slides";
import Fake_SoldLot from "./Fake_SoldLot";
import Fake_ToBeSoldLot from "./Fake_ToBeSoldLot";
import Fake_NextSale from "./Fake_NextSale";
import Fake_OpeningHours from "./Fake_OpeningHours";
import Fake_Promotion from "./Fake_Promotion";
const App = () => {

    return (
        <>
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={Slides}
                    />
                       <Route
                        exact
                        path="/soldlot"
                        component={Fake_SoldLot}
                    />
                     <Route
                        exact
                        path="/tobesoldlot"
                        component={Fake_ToBeSoldLot}
                    />
                    <Route
                        exact
                        path="/nextsale"
                        component={Fake_NextSale}
                    />
                    <Route
                        exact
                        path="/openinghours"
                        component={Fake_OpeningHours}
                    />
                    <Route
                        exact
                        path="/promotion"
                        component={Fake_Promotion}
                    />
                </Switch>
            </Router>
        </>
    ); 
}

export default App;



