import React from "react";
import Promotion from "./Promotion";

export default () => {
    return (
        <Promotion
            config={{
                // alias: "promotion",
                // content: "<p>www.mellorsandkirk.com<br />Tel: 0115 979 0000</p>",
                // heading: "Free Home Visit",
                // horizontalTextPosition: "Right",
                // id: 1186,
                // image: "/media/1006/free-home-visit.png",
                // imageRoot: "https://mkslides.auction2.wrvc.co.uk",
                // name: "Free home visit promo",
                // preHeadingContent: "<p>Please contact us if you would like a</p>",
                // updatedAt: "2019-07-18T15:15:28"

                alias: "promotion",
                content: `<p>Specialist general auctions and valuations of</p>
<ul>
<li>Fine Furniture &amp; Clocks</li>
<li>Pictures</li>
<li>Jewellery &amp; Watches</li>
<li>Silver</li>
<li>Collectors</li>
</ul>`,
                heading: "What we do",
                horizontalTextPosition: "Left",
                id: 1184,
                image: "/media/1015/what-we-do.png",
                imageRoot: "https://mkslides.auction2.wrvc.co.uk",
                name: "What we do promo",
                updatedAt: "2019-07-19T13:35:46"
            }}
        />
    );
};
